var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.coupon)?_c('div',{staticClass:"card widget-settings"},[_c('div',{staticClass:"card-body"},[_c('h5',{staticClass:"h5"},[_vm._v("Meta Data")]),_c('ValidationObserver',{ref:"metaDataForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
var touched = ref.touched;
return [_c('form',{staticClass:"metadata-form",attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.saveSetting)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 boxFile"},[_c('TextInput',{attrs:{"hide-input":_vm.coupon.settings.og_img,"type":"file","name":"favicon","label":"Image ( 512 x auto )","accept":"image/jpeg, image/png"},on:{"changed":function (file) { return _vm.choosedFile(file, 'og_img'); }}}),_c('div',{staticClass:"text-center"},[_c('div',[(_vm.uploadingImage)?_c('b-spinner',{attrs:{"small":""}}):_vm._e()],1),(_vm.coupon.settings.og_img)?_c('img',{staticStyle:{"height":"80px"},attrs:{"src":_vm.coupon.settings.og_img}}):_vm._e(),(_vm.coupon.settings.og_img)?_c('div',{staticClass:"mt-2"},[_c('button',{staticClass:"btn btn-outline-danger btn-sm",on:{"click":function($event){return _vm.deleteFile('og_img')}}},[_vm._v(" Delete ")])]):_vm._e()])],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('TextInput',{attrs:{"label":"Meta Title","name":"Meta Title","sublabel":"(*)","rules":"required"},model:{value:(_vm.coupon.settings.og_title),callback:function ($$v) {_vm.$set(_vm.coupon.settings, "og_title", $$v)},expression:"coupon.settings.og_title"}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Meta Description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('label',[_vm._v("Meta Description "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.coupon.settings.og_desc),expression:"coupon.settings.og_desc"}],staticClass:"form-control",class:{ 'is-invalid': failed },attrs:{"name":"content","rows":"3"},domProps:{"value":(_vm.coupon.settings.og_desc)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.coupon.settings, "og_desc", $event.target.value)}}}),(errors.length)?_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 mt-3 d-flex justify-content-between"},[_c('router-link',{staticClass:"btn btn-outline-dark ",attrs:{"to":{ name: 'business.coupons.index' }}},[_c('span',[_vm._v("Back")])]),_c('b-button',{attrs:{"variant":"primary","disabled":_vm.loadingUpdate || _vm.uploadingImage || (invalid && touched),"type":"submit"}},[(_vm.loadingUpdate)?_c('b-spinner',{attrs:{"small":""}}):_c('span',[_vm._v("Update")])],1)],1)])])]}}],null,false,2043990206)})],1)]):_c('div',{staticClass:"text-center"},[_c('b-spinner')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }