<template>
    <div>
        <div v-if="coupon" class="card widget-settings">
            <div class="card-body">
                <h5 class="h5">Meta Data</h5>
                <ValidationObserver
                    v-slot="{ handleSubmit, invalid, touched }"
                    ref="metaDataForm"
                >
                    <form
                        class="metadata-form"
                        autocomplete="off"
                        @submit.prevent="handleSubmit(saveSetting)"
                    >
                        <div class="row">
                            <div class="col-md-6 boxFile">
                                <TextInput
                                    :hide-input="coupon.settings.og_img"
                                    type="file"
                                    name="favicon"
                                    label="Image ( 512 x auto )"
                                    accept="image/jpeg, image/png"
                                    @changed="(file) => choosedFile(file, 'og_img')"
                                />
                                <div class="text-center">
                                    <div><b-spinner v-if="uploadingImage" small /></div>
                                    <img
                                        v-if="coupon.settings.og_img"
                                        :src="coupon.settings.og_img"
                                        style="height:80px;"
                                    />
                                    <div v-if="coupon.settings.og_img" class="mt-2">
                                        <button
                                            class="btn btn-outline-danger btn-sm"
                                            @click="deleteFile('og_img')"
                                        >
                                            Delete
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <TextInput 
                                    v-model="coupon.settings.og_title"
                                    label="Meta Title"
                                    name="Meta Title"
                                    sublabel="(*)"
                                    rules="required"
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <ValidationProvider
                                        v-slot="{ failed, errors }"
                                        rules="required"
                                        name="Meta Description"
                                    >
                                        <label>Meta Description <span class="text-danger">(*)</span></label>
                                        <textarea
                                            v-model="coupon.settings.og_desc"
                                            name="content"
                                            class="form-control"
                                            rows="3"
                                            :class="{ 'is-invalid': failed }"
                                        ></textarea>
                                        <b-form-invalid-feedback v-if="errors.length">
                                            {{ errors[0] }}
                                        </b-form-invalid-feedback>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 mt-3 d-flex justify-content-between">
                                <router-link class="btn btn-outline-dark " :to="{ name: 'business.coupons.index' }">
                                    <span>Back</span>
                                </router-link>
                                <b-button
                                    variant="primary"
                                    :disabled="loadingUpdate || uploadingImage || (invalid && touched)"
                                    type="submit"
                                >
                                    <b-spinner v-if="loadingUpdate" small />
                                    <span v-else>Update</span>
                                </b-button>
                            </div>
                        </div>
                    </form>
                </ValidationObserver>
            </div>
        </div>
        <div v-else class="text-center">
            <b-spinner></b-spinner>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            coupon: {
                settings: {},
            },
            loading: false,
            loadingUpdate: false,
            uploadingImage: false,
        }
    },

    computed: {

    },

    mounted() {
        this.getCoupon()
    },

    methods: {
        choosedFile(file, type) {
            let uploadForm = new FormData()
            uploadForm.append('type', type)
            uploadForm.append('file', file)

            this.uploadingImage = true
            this.$store
                .dispatch('coupon/uploadImage', uploadForm)
                .then((res) => {
                    this.coupon.settings.og_img = res
                    this.coupon.settings.og_img_type = file.type
                    this.uploadingImage = false
                })
                .catch(() => {
                    this.uploadingImage = false  
                })
        },

        deleteFile(type) {
            switch (type) {
                case 'og_img':
                    this.coupon.settings.og_img = undefined
                    this.coupon.settings.og_img_type = undefined
                    break
            }
        },

        getCoupon() {
            this.loading = true

            this.$store
                .dispatch('coupon/find', this.$route.params.id)
                .then((coupon) => {
                    this.coupon = coupon
                    this.loading = false
                })
                .catch(() => {
                    this.loading = false
                })
        },

        saveSetting() {
            this.loadingUpdate = true

            this.$store
                .dispatch('coupon/updateSetting', {
                    id: this.coupon.id,
                    data: this.coupon.settings
                })
                .then((coupon) => {
                    this.coupon = coupon
                    this.loadingUpdate = false
                })
                .catch((err) => {
                    this.loadingUpdate = false
                    if (err.response.data.errors) {
                        this.$refs.createForm.setErrors(err.response.data.errors)
                    }
                })
        }
    },
}
</script>

<style lang="scss">
.widget-settings {
    .nav-tabs {
        justify-content: center;
        a {
            opacity: 0.6;
            text-align: center;
            &.active {
                background: transparent;
                color: #495057;
                opacity: 1;
            }
        }
        .nav-item {
            flex: 1 1 auto;
        }
    }

    .boxFile {
        .input-group {
            background-color: #4284f4;
            border-color: 1px solid #4284f4;
            border-radius: 0.3rem;
            color: #fff;
            input[type='file'] {
                cursor: pointer;
            }
            &::before {
                position: absolute;
                top: 9px;
                left: calc(50% - 45px);
                font-weight: 600;
                color: #fff;
                pointer-events: none;
                content: 'Upload File';
            }
            &:hover {
                background-color: #1e6cf2;
                border-color: #1265f1;
                &::before {
                  color: #fff;
                }
            }
        }
        .form-control {
            opacity: 0;
        }
    }
}

</style>